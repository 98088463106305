<template lang="pug">
.thanks.mainContainer
  main#main
    .thanksContainer
      .mainImageContainer
        img(:src="mainImage")
      .thanksMessageContainer
        h2.thanksTitle Thank you !
        p.thanksMessage Thank you for your Inquiry. We will get back to you shortly.
        p.backToHome
          a.backToHomeMessage
          router-link.backToHomeMessage(to="/") ← BACK TO HOME
</template>

<script>
import MainImage from "@/assets/main_img.svg"
export default {
  data(){
    return {
      mainImage: MainImage,
    }
  },
}
</script>

<style lang="stylus" scoped>
.thanks
  width: 90%;
  margin: 40px auto;
  padding-bottom: 40px;
.thanksContainer
  display: flex
  flex-direction: row
  .mainImageContainer
    width: 30%!important
    min-width: 100px
    padding-right: 20px
    img
      width:100%
  .thanksMessageContainer
    h2
      font-size: 2rem
      font-weight: bolder
      letter-spacing: 0.1em
    .thanksMessage,
    .backToHome
      margin-top: 20px
      line-height: 2em
      letter-spacing: 0.04em
    .backToHome
      text-align: center
</style>
